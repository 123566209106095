/*
* 업무구분: 내 일정
* 화면 명: MSPAP314M
* 화면 설명: 내 일정
* 화면 접근권한:
*/
<template> 
  <ur-page-container class="msp" :show-title="true" title="내 일정" type="subpage" :action-type="actionSlot" :topButton="false" @on-header-left-click="fn_goMain" :customer="fcData.label" :customerID="fcData.key">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="top-info con-area">
        <div class="remark-box fs12rem">
          <span class="circle yellow">공유</span>
          <span class="circle light-blue">활동</span>
          <span class="circle blue">개인</span>
          <span class="circle green" v-if="userChk">핸드폰</span>
        </div>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-schedule msp-schedule">
        <mo-scheduler ref="loginScheduler" :events="calendarList" displayPeriodUom="month" :displayPeriodCount="1" :showEmptyDay="true"
          :shrink="shrink" @period-changed="fn_PeriodChanged" @click-date="fn_SelectDate"  
          :holidays="holidays" eventTop="31px" eventContentHeight="20px"
          :msg-today="todayString" > 
          <template #month-picker="{year, month}">
            <div>
              {{year}}년 {{month}}월
              <mo-icon icon="msp-calendar"></mo-icon>
            </div>
          </template>
        </mo-scheduler>

      </ur-box-container>
      <div class="floating-wrap ns-floating-wrap" v-if="mngSearch" @click="schlAdd('I')"><!--플로팅 버튼 -->
        <mo-floating-button color="red" class="ico-define"><mo-icon icon="msp-add"/></mo-floating-button>
      </div>
    </ur-box-container>


    <mo-snackbar ref="stackableSnackbar" stackable :duration="3000" ></mo-snackbar>

    <!-- Popup001 -->
    <mo-bottom-sheet ref="schListDtl" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <span>{{ choiceDayFormat }}</span>
        <span class="fs14rem fwn ml10">{{ choiceDayFormat2 }}</span>
        <span v-if="holidayTag" class="badge-type holiday fs12 fwn ml10" >{{ holidayTag }}</span>
      </template>
      <div class="ns-btn-close" @click="close"></div>  <!-- close 버튼 Sample -->

      <!-- content 영역 -->
      <div class="content-area minHauto">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list schedule-item">


          
          <mo-list :list-data="slctModalList">
          <!-- <mo-list :list-data="mokupdata"> -->
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents" :class="{'share' : item.chgclasses === 'share', 'activity' : item.chgclasses === 'activity', 'person' : item.chgclasses === 'person', 'holiday' : item.chgclasses === 'holiday', 'ext'  : item.chgclasses === 'ext'}">
                  <div class="list-item__contents__title fexTy3 mb0 " style="gap: 5px;">
              <!-- <span>
                    <span class="name fs19rem mr10" @click="fn_OpenCustInfoBs(item)">{{ item.title }}</span><mo-icon icon="msp-bell" class="mt5" v-if="item.fcIapTmScCd === '1'" />
                   </span>
                   <mo-button v-if="item.chgclasses === 'share'" class="link-arrow" @click="fn_shareSchdlDtl(item)" /> -->


                    <span :class="item.chgclasses === 'share' && item.sharePmtImgNm ? 'w40' : ''">
                      <span class="name fs19rem mr10" @click="fn_OpenCustInfoBs(item)">{{ item.title }}</span><mo-icon icon="msp-bell" class="mt5" v-if="item.fcIapTmScCd === '1'" />
                    </span>
                   
                    <!-- 프로모션 이미지 버튼 추가 -->
                    <span v-if="item.chgclasses === 'share'">
                      <div>
                        <mo-button v-if="item.sharePmtImgNm" class="ns-btn-round h28 mr10" :class="item.isIn3Month? 'blue-line': ''" @click="fn_LoadImg(item)">
                          <mo-icon icon="clip" class="fs16rem mr5"/>차트보기
                        </mo-button >
                        <mo-button class="link-arrow pb8" @click="fn_shareSchdlDtl(item)" />
                      </div>
                    </span>

                    <span style="min-width:105px;" v-else>
                      <div v-if="item.chgclasses !=='ext'">
                        <mo-button v-show="mngSearch" class="ns-btn-round h28 blue-line mr10" @click="schlAdd('U', item)">수정</mo-button >
                        <mo-button v-show="mngSearch" class="ns-btn-round h28 blue-line" @click="fn_alertOpen(item.fcSchdlSno)">삭제</mo-button>
                      </div>
                    </span>
                  </div>
                  <div class="list-item__contents__info">
                    <div><span v-if="item.CustCntcNm" class="badge-type location fs12 fwn pa10">{{ item.CustCntcNm }}</span></div> 
                    <div><span v-if="item.periodTime" class="crTy-bk1" >{{item.periodTime}}</span></div>
                    <div><span v-if="item.place" class="crTy-bk1" >{{ item.place }}</span></div>
                    <div><span v-if="item.fcSchdlCntnt" class="text-view fs14rem mt10" v-html="item.fcSchdlCntnt"></span></div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </div>
      <template v-slot:action>
        <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs" v-if="mngSearch">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="schlAdd('I', choiceDay)">일정추가</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup001 end-->


    <!-- Popup002 -->
    <!-- 차트보기 -->
    <mo-bottom-sheet ref="sheet2" :close-btn="false"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        차트보기
        <div class="ns-btn-close" @click="fn_ExtImg('close')" name="닫기"></div>
      </template>
      <div class="content-area pb30">
        <ul class="bullet-type-3 crTy-bk7 fs14rem mb10">
          <li>이미지 터치 시 확대할 수 있습니다.</li>
        </ul>
        

        <!-- 슬라이딩 배너 영역 --> 
        <MSPCarousel ref="container1" class="w100 h100 mt10" :number-of-page="lv_ImgList.length" width="auto" height="auto" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" noRotate
                      @on-click="fn_ImgClick">
          <template #1>
            <div>
              <img v-bind:src="lv_ImgList[0]"/>
            </div>
          </template>
          <template #2>
              <div>
                <img v-bind:src="lv_ImgList[1]"/>
              </div>
          </template>
        </MSPCarousel>
        <!-- 슬라이딩 배너 영역 //--> 
        
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_ExtImg()" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup002 end -->

  </ur-page-container> 
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import MSPAP302D from '@/ui/ap/MSPAP302D' // FC 일정추가
  import MSPAP303P from '@/ui/ap/MSPAP303P' // 관리자 일정추가
  import MSPAP304P from '@/ui/ap/MSPAP304P' // 공유일정 상세
  import MSPAP106P from '@/ui/ap/MSPAP106P' // 지점장권한 fc조회
  import apConstants from '@/config/constants/apConstants'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPCarousel from '@/ui/_cmm/components/MSPCarousel'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP314M",
    screenId: "MSPAP314M",
    components: {
      MSPCarousel
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.isLocal = process.env.NODE_ENV === 'local'

      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)
      
      let tmp = this.$route.params

      this.pScrId = tmp.pScrId
      
      if(this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y' && Object.keys(tmp).length !== 0) { // MSPBC003M에서 타 사번 조회된 상태로넘어온 경우
        if(!this.$bizUtil.isEmpty(tmp.cnsltNo)) {
          this.cnsltNo = tmp.cnsltNo
        } else if (tmp.pData?.key){
          this.cnsltNo = tmp.pData.key
          this.fcData  = tmp.pData
        } else {
          this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
        }
      } else {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
      }

      // 지점장 조회권한
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
        this.template = '<span><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_GoMSPAP320M">활동캘린더</mo-button><mo-icon @click="fn_openMSPAP106P">search</mo-icon></span>'
      } else {  // FC 사번 진입 경우
        this.isManager = false
        this.template = '<span><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_GoMSPAP320M">활동캘린더</mo-button></span>'
      }
      
      if (tmp.pSchdlYmd) {
        console.log('라우터 전달 pSchdlYmd!>>>>', tmp.pSchdlYmd)
        this.choiceDay = tmp.pSchdlYmd
        console.log('라우터 전달 매핑!!', this.choiceDay)
      }
      console.log('관리자 여부!!>>', this.isManager)
      
      let lv_vm = this
      // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template: this.template,
          methods: {
            fn_GoMSPAP320M() {
              if(this.mngSearch) {
                this.$router.push({name:'MSPAP320M', params: {cnsltNo: lv_vm.cnsltNo}})
              } else {
                this.$router.push({name:'MSPAP320M', params: {pData: lv_vm.fcData}})
              }
            },
            fn_openMSPAP106P:() => {
              this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
                properties: {
                  pPage: 'MSPAP314M',
                  pData: this.fcData
                },
                listeners: {
                  onPopupSearch: (pItem, pData) => {
                    console.log('onPopupSearch 컨설턴트 >> ' , pItem)
                    console.log('onPopupSearch 컨설턴트 상세 >> ' , pData)
                    // 모달 닫기
                    this.$bottomModal.close(this.popupAP106)
                    if(pItem != null && pItem != undefined){

                      lv_vm.cnsltNm = pItem.label
                      lv_vm.cnsltNo = pItem.key
                      
                      lv_vm.fcData  = pItem

                      lv_vm.fn_PeriodChanged(lv_vm.lv_ChangeMonth)

                    }
                  },
                  onPopupClose: () => {
                    console.log('onPopupClose')
                    // 모달 닫기
                    this.$bottomModal.close(this.popupAP106)
                  }
                }
              })
            }
          }
        }
      }
    },
    async mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      this.userChk = await this.$bizUtil.telSrvcUserChk() && window.vue.getStore('userInfo').getters.getUserInfo.isBMngr === 'N'
      this.fn_OnLoad()
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        fromWidget           : false,
        today                : new Date(),                            // 오늘날짜
        todayString          : `${moment(new Date()).format('D')}일`, // 캘린더에 노출되는 오늘날짜 포맷
        choiceDay            : '',                                    // 선택한 날짜
        choiceDayFormat      : '',                                    // 팝업에 노출될 선택날짜
        choiceDayFormat2     : '',                                    // 팝업에 노출될 선택요일
        focusDate            : null,                                  // focusing 될 날짜
        periodStart          : '',                                    // 3월의경우 3월1일
        periodEnd            : '',                                    // 3월의경우 3월 31일
        lv_NowLoadingHoliday : false,
        lv_SelectedPeriod    : new Date(),                            // 클릭이벤트 안나오게 월을 변경하기 위해 사용되는 변수
        lv_ChangeMonth       : '',
        startSchdDay         : '',
        endSchdDay           : '',
        choiceFcSchdlSno     : 0,
        choiceMobslChnlCustId: '',
        custAlctnCmpgnId     : '',
        holidayList          : [],
        shrink               : false,
        calendarList         : [],                                     // 달력에서 보일 일정 리스트
        modalList            : [],                                     // 모든날짜 담긴 모달 리스트
        slctModalList        : [],                                     // 날짜 클릭시 오픈되는 모달팝업 리스트 
        holidays             : [],                                     // 공휴일 리스트
        holidayTag           : '',                                     // 모달에 보일 공휴일 태그
        isMSPAP304P          : {},
        isManager            : false,                                  // 관리자 여부
        cnsltNm              : '',
        cnsltNo              : '',
        template             : '',
        pScrId               : '',
        isFirst              : true,                                   // MSPBC003M에서 일정 선택하고 넘어온 경우 상세보기 처리
        fcData               : {},

        rruleData            : {},
        nomarlData           : {},
        strDay               : '',
        endDay               : '',
        userChk              : false,

        isLocal: false,

        lv_SelectDay: {},
        
        lv_ImgList: [],

        swiperOption: {
          // centerSlides: true,
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: false,
          /* autoplay: {
            delay: 5000,
            disableOnInteraction: false, 
          }, */
        }
      }
    },
    watch: {
      holidayList () {
        console.log('watch holidayList>>>>')
        this.holidays = []
        for (let i = 0; i < this.holidayList.length; i++) {
          // 법정공휴일 : 1 , 윤년/음력 : 2
          if (this.holidayList[i].holyCd === '1') {
            var tempClass = this.holidayList[i].slrcYmYmd
            this.holidays.push({date: tempClass, label: '', holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color', holyCd: 1})
          } /* else if (this.holidayList[i].holyCd === '2') {
            tempClass = this.holidayList[i].slrcYmYmd
            this.holidays.push({date: tempClass, label: this.holidayList[i].holyNm, holidayClasses: ['holiday-txt-color'], dayNumberClasses: 'holiday-txt-color', holyCd: 2})
          } */
        }
        console.log('holidayList', this.holidayList)
        console.log('this.holidays', this.holidays)
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      getWidgetCngd () {
        return window.vue.getStore('spStore').getters.getState.widFlg
      },
      mngSearch () {
        if(this.cnsltNo != this.getStore('userInfo').getters.getUserInfo.cnsltNo) {
          return false
        } else {
          return true
        }
      },
      isFc () {
        return window.vue.getStore('userInfo').getters.getUserInfo.isFc === 'Y'
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_OnLoad
       * 설명: 최초접근 초기화 프로세스
       *********************************************************/
      fn_OnLoad () {
        // 자식과 오늘 날짜를 공유하기 위한 변수
        this.today = new Date()
        let lv_Vm = this
        // 006P에서 온경우
        let tmp = this.$route.params
        console.log('fn_onLoad() route.params ::', tmp)
        console.log(Boolean(tmp.pSchdlYmd))
        if (tmp.intentType === 'widget') {
        // 위젯에서 온경우
        // 문제시 MenuManger.js에서 'widget' 검색.
          lv_Vm.fromWidget = true
        } else if (window.vue.getStore('spStore').getters.getState.lastPage && window.vue.getStore('spStore').getters.getState.lastPage.name === 'MSPAP314M') {
        // 314 - 위젯 - 302 이후 백키로 돌아왔을때 상태값 처리
          // this.lv_IsMSPAP301M = window.vue.getStore('spStore').getters.getState.lastPage.isMSPAP301M
        } else {
          this.fn_GoToday()
        }
        //this.fn_SendWidgetTodayData()
      },
      /*********************************************************
       * Function명: fn_GoToday
       * 설명: 화면의 오늘날짜노출 버튼 클릭시 실행될 프로세스
       *********************************************************/
      fn_GoToday () {
        this.choiceDay = moment(this.today).format('YYYY-MM-DD')
        this.lv_SelectedPeriod = new Date(this.choiceDay)
        this.focusDate = new Date()
      },
      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        if(this.pScrId === 'MSPBC003M') {
          if(!this.mngSearch) {
            // 화면 이동 시, 검색사번 유지
            this.getStore('bcStore').dispatch('FC_DATA', this.fcData)
          }
          this.$router.go(-1)
        } else {
          this.$router.push({ name: 'MSPBC002M' })
        }
      },
      /******************************************************************************
      * Function명 : fn_MakeString
      * 설명       : 펼쳐진 화면에 나타날 데이터 세팅
      ******************************************************************************/
      fn_MakeString () {
        let ret = ''
        let lv_Vm = this
        for (let i = 0; i < lv_Vm.aPDaySchdlListSVO.length; i++) {
          let campaign = lv_Vm.aPDaySchdlListSVO[i].cntcObjCmpgnNm === undefined || lv_Vm.aPDaySchdlListSVO[i].cntcObjCmpgnNm === '' ? 'null' : lv_Vm.aPDaySchdlListSVO[i].cntcObjCmpgnNm
          let tmpLoca = lv_Vm.fn_MakeSchedulString('where', lv_Vm.aPDaySchdlListSVO[i]) === '' ? '장소 미지정' : lv_Vm.fn_MakeSchedulString('where', lv_Vm.aPDaySchdlListSVO[i])
          ret = ret + lv_Vm.aPDaySchdlListSVO[i].fcSchdlScCd + 'tsssmpA' + lv_Vm.aPDaySchdlListSVO[i].fcSchdlNm + 'tsssmpA' + lv_Vm.fn_MakeSchedulString('when', lv_Vm.aPDaySchdlListSVO[i]) + 'tsssmpA' + tmpLoca + 'tsssmpA' + campaign + 'tsssmpB'
        }
        return ret === '' ? 'null' : ret
      },
      /******************************************************************************
      * Function명 : fn_MakeSchedulString
      * 설명       : 일정의 시간 및 만날장소에 대한 문자열 Control
      ******************************************************************************/
      fn_MakeSchedulString ( code, data) {
        let ret_val = ''
        let lv_Vm = this
        if ( code === 'where' ) {
          if ( data.fcSchdlPrmsPlcNm !== null && data.fcSchdlPrmsPlcNm !== ' ' ) {
            ret_val = lv_Vm.$bizUtil.omitStr( data.fcSchdlPrmsPlcNm, 26 )
          }
        } else if ( code === 'when' ) {
          if ( data.fcSchdlStrYmd === data.fcSchdlEndYmd ) {
            if ( data.fcSchdlStrHm === null || data.fcSchdlEndHm === null ) {
              ret_val = '종일'
            } else {
              ret_val = lv_Vm.fn_ConvertTime(data.fcSchdlStrHm) + ' ~ ' + lv_Vm.fn_ConvertTime(data.fcSchdlEndHm)
              console.log('data.fcSchdlStrHm', data.fcSchdlStrHm)
              console.log('data.fcSchdlStrHm', data.fcSchdlStrHm)
            }
          } else {
            ret_val = data.fcSchdlStrYmd + ' ' + lv_Vm.fn_ConvertTime(data.fcSchdlStrHm) + ' \n~ ' + data.fcSchdlEndYmd + ' ' + lv_Vm.fn_ConvertTime(data.fcSchdlEndHm)
          }
        }
        return ret_val
      },
      /*********************************************************
       * Function명: fn_ConvertTime
       * 설명: 시간데이터 한글화
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_ConvertTime ( data ) {
        console.log('fn_ConvertTime data>>>', data)
        var ret_val = ''
        if ( data === null || data.length !== 4 ) {
          ret_val = ''
        } else {
          if ( Number( data.substring(0, 2)) < 12 ) {
            ret_val = '오전 ' + data.substring(0, 2) + ':' + data.substring(2, 4)
          } else if ( Number( data.substring(0, 2)) > 12 ) {
            let hh = ( Number(data.substring(0, 2)) - 12 )
            hh = ( hh > 9 ? hh : '0' + hh )
            ret_val = '오후 ' + hh + ':' + data.substring(2, 4)
          } else {
            ret_val = '오후 ' + '12' + ':' + data.substring(2, 4)
          }
        }
        return ret_val
      },
       /******************************************************************************
      * Function명 : fn_ModalFormat
      * 설명       : 모달팝업의 시간 && 만날장소 && classes Format
      ******************************************************************************/
      fn_ModalFormat ( code, data) {
        let result = ''
        if ( code === 'where' ) {
          if ( data.place ) {
            result = this.$bizUtil.omitStr( data.place, 26 )
          }
        } else if ( code === 'when' ) {
          if ( data.startDate === data.endDate ) {
            if ( data.startTime === null) {
              result = '하루종일'
            } else {
              result = this.fn_ConvertTime(data.startTime)
            }
          } else {
            if(!this.$bizUtil.isEmpty(data.startTime) && !this.$bizUtil.isEmpty(data.endTime)) {
              if(data.startDate === this.choiceDay) {
                result = this.fn_ConvertTime(data.startTime)
              } else if(data.endDate === this.choiceDay) {
                result = "-" + this.fn_ConvertTime(data.endTime)
              } else {
                result = '하루종일'
              }              
            } else {
              result = '하루종일'
            }
          }
        } else if ( code === 'class') {
          if (data.classes === 'activity-event') {
            result = 'activity'
          } else if (data.classes === 'share-event') {
            result = 'share'
          } else if (data.classes === 'person-event') {
            result = 'person'
          } else if (data.classes === 'ext-event') {
            result = 'ext'
          }
        }
        return result
      },
      /******************************************************************************
      * Function명 : fn_PeriodChanged
      * 설명       : 기간이 변경되었을 때 처리하는 Function
      *            : 기간 관련 변수
      *             displayFirstDate - 달력의 첫날
      *             displayLastDate - 달력의 마지막날
      *             periodStart - 해당 월의 첫날
      *             periodEnd - 해당 월의 마지막날
      ******************************************************************************/
      fn_PeriodChanged (date) {
        this.lv_ChangeMonth = date
        this.startSchdDay = moment(date.displayFirstDate).format('YYYYMMDD')
        this.endSchdDay = moment(date.displayLastDate).format('YYYYMMDD')
        this.periodStart = moment(date.periodStart).format('YYYYMMDD')
        this.periodEnd = moment(date.periodEnd).format('YYYYMMDD')
        this.fn_SelectHolidayList()
        this.fn_SelectListPrdSchdl()
        if (this.lv_FromPeriod) {
          this.focusDate = new Date(this.choiceDay)
          this.lv_FromPeriod = false
        }
        this.choiceDay = moment(this.today).format('YYYY-MM-DD')
        console.log('fn_change>>', this.choiceDay)
      },
      /*********************************************************
       * Function명: fn_SelectHolidayList
       * 설명: 휴일 목록을 조회한다.
       *********************************************************/
      fn_SelectHolidayList () {
        let lv_Vm = this
        if (lv_Vm.lv_NowLoadingHoliday) return
        this.lv_NowLoadingHoliday = true
        lv_Vm.holidayList = []
        lv_Vm.calendarList = []
        let trnstId = 'txTSSAP03S2'
        let pParam = {
          inqrStrYmd: lv_Vm.startSchdDay,
          inqrEndYmd: lv_Vm.endSchdDay
        }
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if ( response.body != null) {
            lv_Vm.iCYmdMngVO = response.body.iCYmdMngVO
            console.log('휴일목록 API response', lv_Vm.iCYmdMngVO)
            if ( lv_Vm.iCYmdMngVO != null) {
              for (let j = 0; j < lv_Vm.iCYmdMngVO.length; j++) {
                let tmpObj = {} // holidays로 전달될 객체
                tmpObj.slrcYmYmd = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
                tmpObj.holyCd = lv_Vm.iCYmdMngVO[j].holyCd
                tmpObj.holyNm = lv_Vm.iCYmdMngVO[j].holyNm
                lv_Vm.holidayList.push(tmpObj)
                if (tmpObj.holyCd === '1') {
                  let tmpObj2 = {} // 모바일에서는 일정처럼 취급하기위해 스케쥴러에 들어갈 객체에 추가로 넣어줌.
                  tmpObj2.startDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
                  tmpObj2.endDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
                  tmpObj2.title = lv_Vm.iCYmdMngVO[j].holyNm
                  tmpObj2.classes = ['holiday', 'holiday-event']
                  lv_Vm.calendarList.push(tmpObj2)
                }
              }
              // if (process.env.NODE_ENV !== 'local') {
              console.log('lv_Vm.calendarList', lv_Vm.calendarList)
              // }
            }
          }
          lv_Vm.lv_NowLoadingHoliday = false
        })
      },
      /*********************************************************
       * Function명: fn_SelectListPrdSchdl
       * 설명: 일정을 조회한다.
       * Params: N/A
       * 변수 :  tmpObj : {
       *                  startDate         : '2019-01-01'  시작일 YYYY-mm-dd
       *                  endDate           : '2019-01-01'  종료일 YYYY-mm-dd ex)2019-01-01
       *                  title             : '제목'
       *                  type              : 1             3: 공유, 1: 활동, 2: 개인, 
       *                  startHm           : 1900          hhmm
       *                  endHm             : 2000          hhmm
       *                  location          : '장소'
       *                  campaign          : '고객배정캠페인명'
       *                  fcIapTmScCd       : '알림설정코드' 1: 당일 , 2: 1일전
       *                  fcSchdlSno        : 'fc일정 일련번호'
       *                  mobslChnlCustId   : ''            모바일 영업채널고객ID
       *                  custAlctnCmpgnId  : '고객배정캠페인ID'
       *                  classes           : '적용되어야할 클래스.'공휴일/음력표시 날짜가 겹칠 경우 공휴일 표기
       *                  }
       * Return: N/A
       *********************************************************/
      fn_SelectListPrdSchdl () {
        let lv_Vm = this
        if (lv_Vm.lv_NowLoadingPrdSchdl) return
        this.lv_NowLoadingPrdSchdl = true
        // lv_Vm.lv_SchdlList.length = 0
        lv_Vm.calendarList = []
        lv_Vm.modalList = []
        // var trnstId = 'txTSSAP20S3'
        var trnstId = 'txTSSAP20S5'
        let tUserEno =  this.cnsltNo
        var pParam = {
          userEno: tUserEno,
          fcSchdlStrYmd: lv_Vm.startSchdDay,
          fcSchdlEndYmd: lv_Vm.endSchdDay
        }
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (response.msgComm.msgCd === 'EFWS004') {
            // 실패시
            window.fdpbridge.exec('ToastPlugin', {message: response.msgComm.msgDesc}, () => {}, () => {})
          }
          if ( response.body != null) {
            lv_Vm.aPSchdlInfoSVO = response.body.aPSchdlInfoSVO
            console.log('lv_Vm.aPSchdlInfoSVO', lv_Vm.aPSchdlInfoSVO)
            if ( response.body.aPSchdlInfoSVO != null) {
              for (let j = 0; j < lv_Vm.aPSchdlInfoSVO.length; j++) {
                let tmpObj = {} // 모달팝업에서 사용될 객체
                let tmpObj2 = {} // fdp-Scheduler에 사용될 객체
                tmpObj2.startDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrYmd)
                tmpObj2.endDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.aPSchdlInfoSVO[j].fcSchdlEndYmd)
                tmpObj2.title = lv_Vm.aPSchdlInfoSVO[j].fcSchdlNm
                if ( lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '1') { // 활동
                  tmpObj2.classes = ['activity-event']
                } else if(lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '2') { // 개인
                  tmpObj2.classes = ['person-event']
                } else if(lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '3'){ // 공유
                  tmpObj2.classes = ['share-event']
                }
                tmpObj.startDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrYmd)
                tmpObj.endDate = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.aPSchdlInfoSVO[j].fcSchdlEndYmd)
                tmpObj.title = lv_Vm.aPSchdlInfoSVO[j].fcSchdlNm
                tmpObj.type = lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd // 1: 활동, 2: 개인, 3: 공유
                tmpObj.startTime = lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrHm === ' ' ? null : lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrHm
                tmpObj2.startTime = lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrHm === ' ' ? null : lv_Vm.aPSchdlInfoSVO[j].fcSchdlStrHm
                tmpObj.endTime = lv_Vm.aPSchdlInfoSVO[j].fcSchdlEndHm === ' ' ? null : lv_Vm.aPSchdlInfoSVO[j].fcSchdlEndHm
                tmpObj.place = lv_Vm.aPSchdlInfoSVO[j].fcSchdlPrmsPlcNm === ' ' ? null : lv_Vm.aPSchdlInfoSVO[j].fcSchdlPrmsPlcNm
                tmpObj.campaign = lv_Vm.aPSchdlInfoSVO[j].custAlctnCmpgnNm
                tmpObj.fcIapTmScCd = lv_Vm.aPSchdlInfoSVO[j].fcIapTmScCd
                tmpObj.fcIapHm = lv_Vm.aPSchdlInfoSVO[j].fcIapHm
                tmpObj.fcSchdlSno = lv_Vm.aPSchdlInfoSVO[j].fcSchdlSno
                tmpObj.mobslChnlCustId = lv_Vm.aPSchdlInfoSVO[j].mobslChnlCustId
                tmpObj.custAlctnCmpgnId = lv_Vm.aPSchdlInfoSVO[j].custAlctnCmpgnId
                tmpObj.fcSchdlCntnt = lv_Vm.aPSchdlInfoSVO[j].fcSchdlCntnt === ' ' ? null : lv_Vm.aPSchdlInfoSVO[j].fcSchdlCntnt
                tmpObj.custCntcId = lv_Vm.aPSchdlInfoSVO[j].custCntcId
                tmpObj.sharePmtImgNm = lv_Vm.aPSchdlInfoSVO[j].sharePmtImgNm
                tmpObj.shareHofOrgNo = lv_Vm.aPSchdlInfoSVO[j].shareHofOrgNo
                tmpObj.shareHofOrgNoNm = lv_Vm.aPSchdlInfoSVO[j].shareHofOrgNoNm
                tmpObj.shareDofOrgNo = lv_Vm.aPSchdlInfoSVO[j].shareDofOrgNo
                tmpObj.shareDofOrgNoNm = lv_Vm.aPSchdlInfoSVO[j].shareDofOrgNoNm
                tmpObj.shareFofOrgNo = lv_Vm.aPSchdlInfoSVO[j].shareFofOrgNo
                tmpObj.shareFofOrgNoNm = lv_Vm.aPSchdlInfoSVO[j].shareFofOrgNoNm
                /* tmpObj.getTitle = (t_obj) => {
                  return t_obj.title
                } */
                if ( lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '1') { // 1: 활동일정
                  tmpObj.classes = 'activity-event'
                } else if (lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '2') { // 2: 개인일정
                  tmpObj.classes = 'person-event'
                } else if(lv_Vm.aPSchdlInfoSVO[j].fcSchdlScCd === '3'){ // 0: 공유일정
                  tmpObj.classes = 'share-event'

                  // 공유일정일 경우, 등록자 정보 세팅
                  tmpObj.regntNo       = lv_Vm.aPSchdlInfoSVO[j].regntNo
                  tmpObj.regntNm       = lv_Vm.aPSchdlInfoSVO[j].regntNm
                  tmpObj.regntFofOrgNm = lv_Vm.aPSchdlInfoSVO[j].regntFofOrgNm
                  tmpObj.regntDofOrgNm = lv_Vm.aPSchdlInfoSVO[j].regntDofOrgNm
                  tmpObj.regntHofOrgNm = lv_Vm.aPSchdlInfoSVO[j].regntHofOrgNm

                  // 차트보기 3개월 이전 일정인 경우, 활성화 class 제거
                  let sDate = moment(tmpObj.startDate).format('YYYYMMDD') // 일정 시작일
                  let d3Month = moment(new Date().setMonth(new Date().getMonth() - 3)).format('YYYYMMDD')

                  tmpObj.isIn3Month = true
                  if (lv_Vm.isFc && lv_Vm.getStore('userInfo').getters.getUserInfo.userId !== tmpObj.regntNo) {
                    if (sDate < d3Month) {
                      tmpObj.isIn3Month = false
                    }
                  }                  
                }

                lv_Vm.modalList.push(tmpObj) // 모달 노출
                lv_Vm.calendarList.push(tmpObj2) // 달력노출
              }
              console.log('modalList>>', lv_Vm.modalList)
              console.log('calendarList>>', lv_Vm.calendarList)
            }
          }
          lv_Vm.lv_NowLoadingPrdSchdl = false

          let today  = moment(lv_Vm.today).format('YYYYMMDD') 
          today      = new Date(parseInt(today.substring(0,4)), parseInt(today.substring(4,6)), parseInt(today.substring(6,8)))

          let strDay = new Date(today)
          strDay.setMonth(strDay.getMonth() - 4)
          strDay.setDate(1)
          lv_Vm.strDay = moment(strDay).format('YYYYMMDD') 

          let endDay = new Date(today)
          endDay.setMonth(endDay.getMonth() + 3)
          endDay.setDate(0)
          lv_Vm.endDay = moment(endDay).format('YYYYMMDD') 

          lv_Vm.fn_ExtschdlLoad()

        }).catch(function (err) {
          console.log('MSPAP314M===>err', err)
        })
      },
      /*********************************************************
       * Function명: fn_SelectDate
       * 설명: 달력의 날짜 클릭
       *********************************************************/
      fn_SelectDate(date, push) {
        this.choiceDay = moment(date).format('YYYY-MM-DD')
        this.choiceDayFormat = `${moment(date).format('M')}월 ${moment(date).format('D')}일`
        console.log('this.choiceDay>>>>', this.choiceDay)
        switch (date.toString().substring(0, 3)) {
          case 'Mon':
            this.choiceDayFormat2 = '월'
            break
          case 'Tue':
            this.choiceDayFormat2 = '화'
            break
          case 'Wed':
            this.choiceDayFormat2 = '수'
            break
          case 'Thu':
            this.choiceDayFormat2 = '목'
            break
          case 'Fri':
            this.choiceDayFormat2 = '금'
            break
          case 'Sat':
            this.choiceDayFormat2 = '토'
            break
          case 'Sun':
            this.choiceDayFormat2 = '일'
            break
        }
        this.slctModalList = [] // 초기화
        let t_slctDay = Number(moment(date).format('D'))
        for (let i = 0; i < this.modalList.length; i++) {
          let t_start = Number(moment(this.modalList[i].startDate).format('D'))
          let t_end = Number(moment(this.modalList[i].endDate).format('D'))
          if (Number(moment(this.modalList[i].startDate).format('M')) === Number(moment(date).format('M'))) {
            // while (t_start <= t_end) {
              if (t_start <= t_slctDay && t_end >= t_slctDay) {
                this.slctModalList.push(this.modalList[i])
              }
              t_start++
            // }       
          }
          
        }
        this.holidayTag = '' // 초기화
        for (let i=0; i < this.holidayList.length; i++) {
          // let t_day = Number(moment(this.holidayList[i].slrcYmYmd).format('D'))
          if (this.holidayList[i].holyCd === '1' && this.holidayList[i].slrcYmYmd === this.choiceDay) {
            this.holidayTag = this.holidayList[i].holyNm
          }
          console.log('모달 공휴일>>>>', this.holidayTag)
        }
        if(this.slctModalList.length != 0) {
          for (let i=0; i < this.slctModalList.length; i++) {
            this.slctModalList[i].place = this.fn_ModalFormat('where', this.slctModalList[i])
            this.slctModalList[i].periodTime = this.fn_ModalFormat('when', this.slctModalList[i])
            this.slctModalList[i].chgclasses = this.fn_ModalFormat('class', this.slctModalList[i])
              apConstants.mngrCustCntc.forEach(v => {
                if (v.id === this.slctModalList[i].custCntcId) {
                  this.slctModalList[i].CustCntcNm = v.name
                }
              })
              apConstants.fcCustCntc.forEach(v => {
                if (v.id === this.slctModalList[i].custCntcId) {
                  this.slctModalList[i].CustCntcNm = v.name
                }
              })

              if(!this.$bizUtil.isEmpty(this.slctModalList[i].fcSchdlCntnt)) {
                this.slctModalList[i].fcSchdlCntnt = this.slctModalList[i].fcSchdlCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
              }
          }
        }

        if(this.slctModalList.length === 0 && push === 'Y') {
          this.schlAdd('I', this.choiceDay)
        }
        console.log('this.slctModalList>>>', this.slctModalList)

        if(this.slctModalList.length > 0) {
          this.$refs.schListDtl.open()
        } else {
          if(this.mngSearch) {
            this.schlAdd('I', this.choiceDay)
          }
        }
      },
      /*********************************************************
       * Function명: fn_alertOpen
       * 설명: 삭제버튼 클릭시 알럿 오픈
       *********************************************************/
      fn_alertOpen(data) {
        this.choiceFcSchdlSno = Number(data)
        this.fn_BottomConfirm('일정을 삭제하시겠습니까?')
      },
      /*********************************************************
       * Function명: fn_BottomConfirm
       * 설명: 알럿 오픈
       *********************************************************/
      fn_BottomConfirm (contents) {
        this.confirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: '',
          content: contents,
          title_neg_btn:'취소',
          title_pos_btn: '삭제'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_DeleteProcess()
            this.$bottomModal.close(this.confirmPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.confirmPop);
            }
          }
        })
      },
      /*********************************************************
       * Function명: fn_DeleteProcess
       * 설명: 삭제처리 프로세스
       *********************************************************/
      fn_DeleteProcess () {
          var lv_Vm = this
          var trnstId = 'txTSSAP20D1'
          var pParam = {
            userEno: this.cnsltNo,
            fcSchdlSno: Number(lv_Vm.choiceFcSchdlSno)
          }
          lv_Vm.post(lv_Vm, pParam, trnstId, 'D')
            .then(function (response) {
              if (response.msgComm.msgCd === 'ECOU004') {
                lv_Vm.choiceFcSchdlSno = 0
                lv_Vm.choiceMobslChnlCustId = ''
                lv_Vm.fn_SelectHolidayList()
                lv_Vm.fn_SelectListPrdSchdl()
                lv_Vm.$refs.schListDtl.close()
                lv_Vm.fn_DelToast()
              } else {
                console.log('저장 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc )
              }
            })
      },

      onScheduleModifyClick(dayEvent) {
        console.log('modify click', dayEvent);
      },

      onScheduleDeleteClick(dayEvent) {
        console.log('delete click', dayEvent);
      },

      schlAdd(cd, data) {
        console.log('isManager>>>>', this.isManager)
        console.log('data>>>>', data)
        if (cd === 'I') {
        this.choiceFcSchdlSno = 0
        this.choiceMobslChnlCustId = ''
        this.custAlctnCmpgnId = ''
        } else {
          // this.isManager = true //TEST
          this.choiceFcSchdlSno = Number(data.fcSchdlSno)
          this.choiceMobslChnlCustId = data.mobslChnlCustId
          this.custAlctnCmpgnId = data.custAlctnCmpgnId
        }
        console.log('choiceDay>>>>>>>>>>>', this.choiceDay)
        console.log('choiceFcSchdlSno>>>>', this.choiceFcSchdlSno)
        console.log('choiceMobslChnlCustId>>>>', this.choiceMobslChnlCustId)
        console.log('custAlctnCmpgnId>>>>', this.custAlctnCmpgnId)
        if (this.isManager) {
          // 관리자 일정추가
          this.fullPopup = this.$bottomModal.open(MSPAP303P, {
            properties: {
              pSchdlItem: {},
              pFcSchdlSno: this.choiceFcSchdlSno,
              pMobslChnlCustId: this.choiceMobslChnlCustId,
              pCustAlctnCmpgnId: this.custAlctnCmpgnId,
              pChoiceYmd: this.choiceDay,
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.fullPopup);
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.fullPopup);
              },
              fn_OnRefresh: () => {
                this.fn_SelectHolidayList()
                this.fn_SelectListPrdSchdl()
                this.$bottomModal.close(this.fullPopup);
                this.$refs.schListDtl.close()
              }
            }
          },
          /* {
            properties: {
              noHeader: true
            }
          } */)
        } else {
          // FC 일정추가
          this.fullPopup = this.$bottomModal.open(MSPAP302D, {
            properties: {
            pPage: 'MSPAP302D',
            pIsBackKeyListen: true,
            pFcSchdlSno: this.choiceFcSchdlSno,
            pMobslChnlCustId: this.choiceMobslChnlCustId,
            pCustAlctnCmpgnId: this.custAlctnCmpgnId,
            pChoiceYmd: this.choiceDay,
            pSrnId: 'MSPAP301M'
          },
          listeners: {
            closeDivision: () => {
              console.log('closeDivision')
              this.choiceFcSchdlSno = 0
              this.$bottomModal.close(this.fullPopup)
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              this.$bottomModal.close(this.fullPopup)
              this.fn_SelectHolidayList()
              this.fn_SelectListPrdSchdl()
            },
            fn_OnRefresh: () => {
                this.fn_SelectHolidayList()
                this.fn_SelectListPrdSchdl()
                this.$refs.schListDtl.close()
              }
            }
          })
        }
      },
      /*********************************************************
       * Function명: fn_shareSchdlDtl
       * 설명: 공유일정 상세
       *********************************************************/
      fn_shareSchdlDtl(param) {
        console.log('상세화면 넘길 param>>', param)
        this.isMSPAP304P = this.$bottomModal.open(MSPAP304P, {
            properties: {
            pschdlDtl: param,
          },
          listeners: {
            onPopupClose: () => {
              console.log('onPopupClose')
              this.$bottomModal.close(this.isMSPAP304P)
              this.fn_SelectHolidayList()
              this.fn_SelectListPrdSchdl()
            },
            fn_OnRefresh: () => {
                this.$bottomModal.close(this.isMSPAP304P)
                this.fn_SelectHolidayList()
                this.fn_SelectListPrdSchdl()
                this.$refs.schListDtl.close()
            }
          }
        })
      },

      /******************************************************************************
      * Function명  : fn_ExtschdlLoad
      * 설명        : 핸드폰일정일정조회
      ******************************************************************************/
      fn_ExtschdlLoad() {
        let lv_Vm = this

        // rrule 데이터 조회기간
        let rruleEnd   = Number(moment(lv_Vm.today).format('YYYY')) + 5 + '1231'
        let rruleStart = Number(moment(lv_Vm.today).format('YYYY')) - 5 + '0101'

        // 로컬 테스트
        if (process.env.NODE_ENV === 'local') {
          this.fn_viewExtSchl()
          return false
        }

        if(lv_Vm.userChk && localStorage.getItem('CLDR_SYNC_YN') === 'Y') {
          if(lv_Vm.strDay <= lv_Vm.startSchdDay && lv_Vm.endDay >= lv_Vm.endSchdDay) {
            window.fdpbridge.exec('calendarGetInfoPlugin', {mode:'1', startDay:rruleStart, endDay:rruleEnd}, function (result) {
              // 성공콜백
              console.log("rrule 성공", result.data)
              lv_Vm.rruleData = result.data

              window.fdpbridge.exec('calendarGetInfoPlugin', {mode:'2', startDay:lv_Vm.strDay, endDay:lv_Vm.endDay}, function (result) {
                // 성공콜백
                console.log("normal 성공", result.data)
                lv_Vm.normalData = result.data
                lv_Vm.fn_viewExtSchl()
              },(error) => {
                if(lv_Vm.$route.params.selectSchd && lv_Vm.isFirst) {
                  let date      = new Date()
                  lv_Vm.isFirst = false
                  lv_Vm.fn_SelectDate(date ,false)
                }
                console.log('normal 실패', error)
              })
            },(error) => {
              lv_Vm.$bizUtil.apUtils.saveSysInfo('N')
              if(lv_Vm.$route.params.selectSchd && lv_Vm.isFirst) {
                let date      = new Date()
                lv_Vm.isFirst = false
                lv_Vm.fn_SelectDate(date ,false)
              }
              console.log('rrule 실패', error)
            })
          }
          else {
            window.fdpbridge.exec('calendarGetInfoPlugin', {mode:'2', startDay:lv_Vm.startSchdDay, endDay:lv_Vm.endSchdDay}, function (result) {
              // 성공콜백
              console.log("범위 밖 플러그인 호출 성공", result.data)
              lv_Vm.normalData = result.data
              lv_Vm.fn_viewExtSchl(true)
            },(error) => {
              lv_Vm.$bizUtil.apUtils.saveSysInfo('N')
              if(lv_Vm.$route.params.selectSchd && lv_Vm.isFirst) {
                let date      = new Date()
                lv_Vm.isFirst = false
                lv_Vm.fn_SelectDate(date ,false)
              }
              console.log("범위 밖 플러그인 호출 실패", error)
            })
          }
        }
      },
      /******************************************************************************
      * Function명  : fn_viewExtSchl
      * 설명        : 핸드폰일정일정세팅
      ******************************************************************************/
      fn_viewExtSchl(saveYn = false) {
        // TEST DATA

        if (process.env.NODE_ENV === 'local') {
          this.rruleData  = "[{\"_id\":\"1011\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"19700101,0900\",\"dtstart\":\"20250122,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"FREQ\\u003dWEEKLY;INTERVAL\\u003d2;WKST\\u003dSU;BYDAY\\u003dWE\",\"title\":\"테스트3\"}]"
          this.normalData = "[{\"_id\":\"1083\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250220,1700\",\"dtstart\":\"20250224,1600\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트111\"}, {\"_id\":\"1088\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250131,1500\",\"dtstart\":\"20250131,1400\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트2\"}, {\"_id\":\"1089\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"ㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷ더ㅗㅗㅗㅗㅗㅓㅜㅝ더더더더더더거거거우우ㅜㅜㅜㅜㅜㅡㅡㅡㅡㅡㅜㅜ\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"\"}, {\"_id\":\"1090\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트33\"}, {\"_id\":\"1091\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트4\"}]"
        }
      
        try {        
          const rruleJsonString  = this.rruleData
          const normalJsonString = this.normalData

          // 세팅할 일정 기간 설정
          let startSchdDay = this.startSchdDay
          let endSchdDay   = this.endSchdDay
          // let start        = new Date(startSchdDay.substring(0,4), startSchdDay.substring(4,6) -1, startSchdDay.substring(6,8))
          // let end          = new Date(endSchdDay.substring(0,4), endSchdDay.substring(4,6) -1, endSchdDay.substring(6,8))
          // let searchDate = null 

          // 화면에 보여줄 데이터만 가공
          let extSchdl = this.$bizUtil.apUtils.parseJsonCal(rruleJsonString, normalJsonString, startSchdDay, endSchdDay)

          for(let i=0; i<extSchdl.length; i++) {
            let tmpObj = {}                                    // 모달팝업에서 사용될 객체
            let tmpObj2 = {}                                   // fdp-Scheduler에 사용될 객체
            tmpObj2.startDate       = extSchdl[i].startDate
            tmpObj.startDate        = extSchdl[i].startDate
            tmpObj2.classes         = ['ext-event']

            if(this.$bizUtil.isEmpty(extSchdl[i].title)) {
              tmpObj.title          = '제목없음'
              tmpObj2.title         = '제목없음'
            } else {
              tmpObj.title          = extSchdl[i].title
              tmpObj2.title         = extSchdl[i].title
            }

            if(extSchdl[i].allDay === "0") {
              tmpObj.startTime      = extSchdl[i].startTime
              tmpObj.endTime        = extSchdl[i].endTime
              tmpObj2.startTime     = extSchdl[i].startTime
              tmpObj.endDate        = extSchdl[i].endDate
              tmpObj2.endDate       = extSchdl[i].endDate
            } else {
              tmpObj.startTime      = null
              tmpObj.endTime        = null
              tmpObj2.startTime     = null

              let end = null
              if (extSchdl[i].isRrule === true) {
                end = new Date(extSchdl[i].startDate)
              } else {
                end = new Date(extSchdl[i].endDate)
                end.setDate(end.getDate()-1)
              }
              
              tmpObj.endDate        = end.toISOString().split('T')[0]
              tmpObj2.endDate       = end.toISOString().split('T')[0]
            }
            
            tmpObj.place            = extSchdl[i].place
            tmpObj.fcSchdlCntnt     = extSchdl[i].fcSchdlCntnt
            tmpObj.classes          = 'ext-event'

            this.modalList.push(tmpObj)      // 모달 노출
            this.calendarList.push(tmpObj2)  // 달력노출
          }



          // 데이터 정렬 
          let data = this.modalList
          
          data.sort((a,b) => {
            if(a.startDate !== b.startDate) {
              return a.startDate.localeCompare(b.startDate)
            }
            return a.startTime - b.startTime
          })
          this.modalList = data

          if(this.$route.params.selectSchd && this.isFirst) {
            let date      = new Date()
            this.isFirst = false
            this.fn_SelectDate(date ,false)
          }

          if(!saveYn) {
            this.fn_extSchlAdd()
          }
        } catch(error) {
          console.log('error', error)
        }
      },
      /******************************************************************************
      * Function명  : fn_extSchlAdd
      * 설명        : 핸드폰일정등록
      ******************************************************************************/
      async fn_extSchlAdd() {

        // TEST DATA
        // let r = "[{\"_id\":\"1011\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"19700101,0900\",\"dtstart\":\"20250122,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"FREQ\\u003dWEEKLY;INTERVAL\\u003d2;WKST\\u003dSU;BYDAY\\u003dWE\",\"title\":\"테스트3\"}]"
        // let n = "[{\"_id\":\"1083\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250122,1700\",\"dtstart\":\"20250122,1600\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트111\"}, {\"_id\":\"1088\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250131,1500\",\"dtstart\":\"20250131,1400\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트2\"}, {\"_id\":\"1089\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"ㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷ더ㅗㅗㅗㅗㅗㅓㅜㅝ더더더더더더거거거우우ㅜㅜㅜㅜㅜㅡㅡㅡㅡㅡㅜㅜ\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"\"}, {\"_id\":\"1090\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트33\"}, {\"_id\":\"1091\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트4\"}]"
        let trnstId = 'txTSSAP20I2'

        let pParam = {}

        let rrule  = JSON.parse(this.rruleData)
        let normal = JSON.parse(this.normalData)

        // 삭제한 이벤트 처리(DB에 넣지 않음)
        let filterdEvents = normal.concat(rrule)
        try {
          filterdEvents = filterdEvents.filter(event => !(event.original_id === '' && event.status === '2'))
        } catch (error) {
          console.log('fn_extSchlAdd - error', error)
        }
        
        pParam.extSchlList = filterdEvents
        pParam.strDay      = this.strDay
        pParam.endDay      = this.endDay
        pParam.deviceId    = await this.$commonUtil.getDeviceIDPlugin(this)

        this.post(this, pParam, trnstId, 'I')
        .then(function (response) {

        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명  : fn_extSchdlAlert
      * 설명        : 핸드폰일정 수정/삭제 버튼 이벤트
      ******************************************************************************/
      fn_extSchdlAlert() {
        let msg = '핸드폰에서 가져온 일정은<br>사랑On에서 수정/삭제 불가합니다.<br><br>삼성/구글 캘린더에서 변경 후 재접속하면<br>사랑On에도 반영됩니다.' 
        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            content: msg,
            title: '',
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })
      },

      close() { this.$refs.schListDtl.close(); },

      fn_DelToast(){
        this.$refs.stackableSnackbar.show({text:'삭제되었습니다.'});
      },
      /******************************************************************************
      * Function명  : fn_OpenCustInfoBs
      * 설명        : 네임카드 호출 함수
      ******************************************************************************/
      fn_OpenCustInfoBs(item) {
        const lv_Vm = this

        if (item.type !== '1') return // 공유일정은 제외
        
        let cnsltNo = window.vue.getStore('userInfo').getters.getUserInfo.cnsltNo
        let chnlCustId = item.mobslChnlCustId
        let custNm = item.title
        let nmUiObject = {}

        nmUiObject = {
          chnlCustId : chnlCustId, //고객채널 ID
          parentId   : lv_Vm.$options.screenId, //화면ID+
          cnsltNo : cnsltNo,
          custNm : custNm,
          contNo : ''
        }

        lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
          //Close 콜백 처리
          return
        })
      },
      /******************************************************************************
      * Function명  : fn_LoadImg
      * 설명        : 이미지 조회
      ******************************************************************************/
      fn_LoadImg(item) {
        let sDate = moment(item.startDate).format('YYYYMMDD') // 일정 시작일
        let d3Month = moment(new Date().setMonth(new Date().getMonth() - 3)).format('YYYYMMDD')

        console.log('Show promotion image!', item)
        console.log('Show Date!!', sDate, d3Month)

        if (this.isFc && this.getStore('userInfo').getters.getUserInfo.userId !== item.regntNo) {
          if (sDate <= d3Month) {
            let props = {msg: '3개월 내 차트만 열람 가능합니다.'}
            this.$cmUtil.fn_OpenAlert(this, props)
            return
          }
        }

        this.lv_ImgList = []

        const lv_Vm = this
        const auth = 'S'
        const trnstId = 'txTSSAP20S6'

        let pParams = {
          fcSchdlScCd: '3',
          fcSchdlSno: item.fcSchdlSno
        }

        this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          console.log('Promotion image load!!', res)

          if (!_.isEmpty(res.body)) {
            if (!this.$bizUtil.isEmpty(res.body.imgFstData)) {
              lv_Vm.lv_ImgList.push(res.body.imgFstData)
            }

            if (!this.$bizUtil.isEmpty(res.body.imgScndData)) {
              lv_Vm.lv_ImgList.push(res.body.imgScndData)
            }

            if (res.body.imgFstData === '0') { // 1년지난 이미지 조회
              this.$addSnackbar('이미지 보관일이 지났습니다.')
            } else if (res.body.imgFstData === '-1') { // 파일이 존재하지 않을 경우
              this.$addSnackbar('이미지 조회를 실패하였습니다.')
            } else {
              lv_Vm.lv_SelectDay = item

              lv_Vm.$refs.sheet2.open()
            }
          } else {
            this.$addSnackbar('이미지 조회를 실패하였습니다.')
          }
        })
        .catch(err => {
          this.$addSnackbar('시스템 오류가 발생하였습니다.')
        })
        
      },
      /******************************************************************************
      * Function명  : fn_ExtImg
      * 설명        : 차트 팝업 닫기
      ******************************************************************************/
      fn_ExtImg() {
        this.lv_SelectDay = {} // 초기화
        this.$refs.sheet2.close()
      },
      /******************************************************************************
      * Function명  : fn_ZoomViewer
      * 설명        : 이미지 줌 플러그인 호출
      ******************************************************************************/
      fn_ZoomViewer (idx) {
        let imgData = this.lv_ImgList[idx]

        window.fdpbridge.exec('ZoomViewerPlugin', {
          img: imgData
        }, () => {
          console.log(':: ZoomPlugin success')
        }, (error) => {
          console.log(':: ZoomPlugin fail')
          window.vue.error(error)
        });

      },
      /******************************************************************************
      * Function명  : fn_ImgClick
      * 설명        : 이미지 클릭 이벤트
      ******************************************************************************/
      fn_ImgClick(idx) {
        console.log('fn_ImgClick', idx)
        this.fn_ZoomViewer(idx)
      }
    }
  };
</script>
<style scoped>
</style>