/*
* 업무구분: 일정 상세
* 화면 명: MSPAP304P
* 화면 설명: 일정 상세
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" :show-title="true" title="공유일정" type="subpage" :topButton="false" @on-header-left-click="fn_close">
    
    <!-- 전체체크 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area bd-b-Ty3">
        <div class="mt20 mb20 fexTy2">
          <span class="fwb fs19rem flex-1 mr10">{{ showSchdlDtl.title }}</span>
          <span class="badge-type location fs12 fwn mt5 pa10">{{ showSchdlDtl.CustCntcNm }}</span>
        </div>
      </ur-box-container>
    </template>

    <!-- 스크롤 List -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area">      
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
          <!-- 등록자 영역 -->
          <div class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="notice" class="mr20 mt3" />
            <span class="fs17rem flex-1">
              <!-- <span>{{ showSchdlDtl.regntHofOrgNm }}</span><em class="pl4 pr4">–</em>
              <span>{{ showSchdlDtl.regntDofOrgNm }}</span><em class="pl4 pr4">–</em> -->
              <span>{{ showSchdlDtl.regntFofOrgNm }}</span><em class="pl4 pr4">–</em>
              <span>{{ showSchdlDtl.regntNm }}</span>
            </span>
          </div>
          <!-- 공유대상조직 영역 -->
          <div class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-group" class="mr20 mt3" />
            <span class="fs17rem flex-1">
              <span>{{ showSchdlDtl.shareHofOrgNoNm !== null ? showSchdlDtl.shareHofOrgNoNm: '전사' }}</span><em class="pl4 pr4">–</em>
              <span>{{ showSchdlDtl.shareDofOrgNoNm !== null ? showSchdlDtl.shareDofOrgNoNm: '전체' }}</span><em class="pl4 pr4">–</em>
              <span>{{ showSchdlDtl.shareFofOrgNoNm !== null ? showSchdlDtl.shareFofOrgNoNm: '전체' }}</span>
            </span>
          </div>
          <!--시간 영역-->
          <div class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-watch" class="mr20 mt3" />
            <div class="flex-1">
              <!--종일-->
              <!-- <span v-if="showSchdlDtl.startTime === null || showSchdlDtl.endTime === null" class="fs17rem">
                <span>하루종일</span>
              </span> -->
              <span class="fs17rem fexTy5">
                <span class="txt-center">{{ showSchdlDtl.startDate }}<br>{{ showSchdlDtl.startTime === null ? '종일' : showSchdlDtl.startTime }}<br/></span>
                <mo-icon icon="msp-arrow-line" class="ml30 mr30" />
                <span class="txt-center">{{ showSchdlDtl.endDate }}<br>{{ showSchdlDtl.endTime === null ? '종일' : showSchdlDtl.endTime }}<br/></span>
              </span>
            </div>
          </div>

          <!-- 첨부 이미지 영역 -->
          <ur-box-container class="add-box">
            <!-- 슬라이딩 배너 영역 --> 
            <MSPCarousel ref="container1" class="w100 h100" :number-of-page="lv_ImgList.length" width="auto" height="auto" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" noRotate
                          @on-click="fn_ImgClick">
              <template #1>
                <div>
                  <img v-bind:src="lv_ImgList[0]"/>
                </div>
              </template>
              <template #2>
                  <div>
                    <img v-bind:src="lv_ImgList[1]"/>
                  </div>
              </template>
            </MSPCarousel>
            <!-- 슬라이딩 배너 영역 //--> 
          </ur-box-container>
          <!-- 첨부 이미지 영역 //-->

          <!--위치 영역-->
          <div v-if="showSchdlDtl.place" class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-location" class="mr20 mt3" /> 
            <span class="fs17rem flex-1">
              <span>{{ showSchdlDtl.place }}</span>
            </span>
          </div>
          <!--내용 영역-->
          <div v-if="showSchdlDtl.fcSchdlCntnt" class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-doc" class="mr20 mt3" />
            <span class="fs17rem flex-1">
              <span v-html="showSchdlDtl.fcSchdlCntnt"></span>
            </span>
          </div>
          <!--푸쉬영역-->
          <div class="pt20 pb20 bd-b-Ty3 full fexTy2 mb70">
            <mo-icon icon="msp-bell" class="mr20 mt3" /> 
            <span v-if="showSchdlDtl.fcIapTmScCd !== ' '" class="fs17rem flex-1">
              <span>
                {{ fcIapDay }}
                {{ fcIapHm }}
              </span>
            </span>
            <span v-else class="fs17rem flex-1">
              <span>사용안함</span>
            </span>
          </div>


        </ur-box-container>
      </ur-box-container>

    </ur-box-container>

    <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column"  class="ns-btn-relative">
      <div class="relative-div">
        <mo-button v-if="isDtl" componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_alertOpen('D')">삭제</mo-button>
        <mo-button v-if="isDtl" componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_alertOpen('U')">수정</mo-button>
        <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_close">확인</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import apConstants from '@/config/constants/apConstants'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPCarousel from '@/ui/_cmm/components/MSPCarousel'

  import MSPAP303P from '@/ui/ap/MSPAP303P' // 관리자 일정수정

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP304P",
    screenId: "MSPAP304P",
    components: {
      MSPCarousel
    },
    props: {
      pschdlDtl: {
        type: Object,
        default: {}
      },
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.showSchdlDtl = this.$bizUtil.cloneDeep(this.pschdlDtl)
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
      } else {
        this.isManager = false
      }

	  // 공율일정 등록한 사번만 삭제가능
      if(this.showSchdlDtl.regntNo === this.getStore('userInfo').getters.getUserInfo.userEno) {
        this.isDtl = true
      } else {
        this.isDtl = false
      }
      console.log('관리자 여부!!>>', this.isManager)
      console.log('공유일정상세 정보>>>>>', this.showSchdlDtl)

      this.fn_SearchSchedule()
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isShow: false,
        isManager: false, // 관리자 여부
        showSchdlDtl: {},
        fcIapDay: '', // 알림일
        fcIapHm : '', // 알림시간

        isDtl : false, // 삭제 가능 여부
        isUdtData: false, // 수정 여부

        udtFcSchdlSno: 0, // 수정된 일정Sno

        lv_ImgList: [],
        lv_ImgListBackup: [],
        
        swiperOption: {
          // centerSlides: true,
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: false,
          /* autoplay: {
            delay: 5000,
            disableOnInteraction: false, 
          }, */
        },
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_LoadImg
       * 설명: 첨부 이미지 조회
       *********************************************************/
      fn_LoadImg() {
        const lv_Vm = this
        const auth = 'S'
        const trnstId = 'txTSSAP20S6'

        let pParams = {
          fcSchdlScCd: '3',
          fcSchdlSno: 0
        }

        if (this.udtFcSchdlSno > 0) {
          pParams.fcSchdlSno = Number(this.udtFcSchdlSno)
        } else {
          pParams.fcSchdlSno = Number(this.showSchdlDtl.fcSchdlSno)
        }

        lv_Vm.lv_ImgList = []
        this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          console.log('Promotion image load!!', res)

          if (!_.isEmpty(res.body)) {
            // 첫번째 이미지
            if (!this.$bizUtil.isEmpty(res.body.imgFstData)) {
              if (res.body.imgFstData === '0') { // 1년지난 이미지 조회
                this.$addSnackbar('이미지 보관일이 지났습니다.')
              } else {
                lv_Vm.lv_ImgList.push(res.body.imgFstData)
              }
            }

            // 두번째 이미지
            if (!this.$bizUtil.isEmpty(res.body.imgScndData)) {
              if (res.body.imgScndData === '0') { // 1년지난 이미지 조회
                this.$addSnackbar('이미지 보관일이 지났습니다.')
              } else {
                lv_Vm.lv_ImgList.push(res.body.imgScndData)
              }
            }
            
          } else {
            this.$addSnackbar('이미지 조회를 실패하였습니다.')
          }
        })
        .catch(err => {
          this.$addSnackbar('시스템 오류가 발생하였습니다.')
        })
        
      },

      /*********************************************************
       * Function명: fn_ConvertTime
       * 설명: 시간데이터 한글화
       *********************************************************/
      fn_ConvertTime ( data ) {
        console.log('fn_ConvertTime data>>>', data)
        var ret_val = ''
        if ( data === null || data.length !== 4 ) {
          ret_val = ''
        } else {
          if ( Number( data.substring(0, 2)) < 12 ) {
            ret_val = '오전 ' + data.substring(0, 2) + ':' + data.substring(2, 4)
          } else if ( Number( data.substring(0, 2)) > 12 ) {
            let hh = ( Number(data.substring(0, 2)) - 12 )
            hh = ( hh > 9 ? hh : '0' + hh )
            ret_val = '오후 ' + hh + ':' + data.substring(2, 4)
          } else {
            ret_val = '오후 ' + '12' + ':' + data.substring(2, 4)
          }
        }
        return ret_val
      },
      /*********************************************************
       * Function명: fn_alertOpen
       * 설명: 삭제버튼 클릭시 알럿 오픈
       *********************************************************/
      fn_alertOpen(flag) {
        let alertMsg = ''

        if (this.showSchdlDtl.fcIapTmScCd !== ' ') {
          let t_fcIap = moment(`${this.fcIapDay} ${this.showSchdlDtl.fcIapHm.substring(0, 2)}:${this.showSchdlDtl.fcIapHm.substring(2, 4)}`).format('YYYY-MM-DD HH:mm')
          let t_today = moment(new Date()).format('YYYY-MM-DD HH:mm')
          let diff = moment.duration(moment(t_fcIap).diff(t_today)).asMinutes()
          console.log('알람시간 확인!!>>', t_fcIap)
          console.log('현재시간 확인!!>>', t_today)
          console.log('분 비교!!>>', diff )

          if (flag === 'U') {
            alertMsg = '알림시간 10분전부터 수정이 불가능합니다.'
          } else if (flag === 'D') {
            alertMsg = '알림시간 10분전부터 삭제가 불가능합니다.'
          }

          if (0 < Number(diff) && Number(diff) <= 10) {
            this.fn_BottomAlert(alertMsg)
            return
          }
        }

        if (flag === 'U') {
          alertMsg = '일정을 수정하시겠습니까?'
        } else if (flag === 'D') {
          alertMsg = '일정을 삭제하시겠습니까?'
        }

        this.fn_BottomConfirm(alertMsg, flag)
      },
      /*********************************************************
       * Function명: fn_BottomConfirm
       * 설명: 버튼 2개 알럿 오픈
       *********************************************************/
      fn_BottomConfirm (contents, flag) {
        const lv_Vm = this
        let posBtn = flag === 'U' ? '수정' :'삭제'

        this.confirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: '',
          content: contents,
          title_neg_btn:'취소',
          title_pos_btn: posBtn
        },
        listeners: {
          onPopupConfirm: () => {
            if (flag === 'U') {
              lv_Vm.fn_UpdateProcess()
            } else if (flag === 'D') {
              lv_Vm.fn_DeleteProcess()
            }
            this.$bottomModal.close(lv_Vm.confirmPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_Vm.confirmPop);
            }
          }
        })
      },

      /******************************************************************************
        * Function명 : fn_BottomAlert
        * 설명       : 싱글 버튼 알림 팝업 호출
        ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: contents,
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            }
          }
        })
      },

      /*********************************************************
         * Function명: fn_ServerTimeComprfcIapHm
         * 설명: 최초 시작시간 설정 (현재시간보다 + 1)
         *********************************************************/
        fn_ServerTimeComprfcIapHm (time) {
          let timeA, timeB, timeHm, timeMm
            timeA = time.substring(5, 8)
            timeB = time.substring(0, 2)
            if (timeA === '오전' || timeA === '오후') {
              timeHm = time.substring(0, 2)
              timeMm = time.substring(3, 5)
              if (timeA === '오전') {
                if (Number(timeHm) === 12) {
                  time = '00' + timeMm
                } else {
                  time = timeHm + timeMm
                }
              } else {
                if (Number(timeHm) === 12) {
                  time = timeHm + timeMm
                } else {
                  time = Number(timeHm) + 12 + timeMm
                }
              }
            } else {
              timeHm = time.substring(3, 5)
              timeMm = time.substring(6, 8)
              if (timeB === '오전') {
                if (Number(timeHm) === 12) {
                  time = '00' + timeMm
                } else {
                  time = timeHm + timeMm
                }
              } else {
                if (Number(timeHm) === 12) {
                  time = timeHm + timeMm
                } else {
                  time = Number(timeHm) + 12 + timeMm
                }
              }
            }
          console.log('fn_ServerTimeComprfcIapHm', time)
          return time
        },

      /*********************************************************
       * Function명: fn_UpdateProcess
       * 설명: 수정처리 프로세스
       *********************************************************/
      fn_UpdateProcess () {
        const lv_Vm = this

        let schdlItem = this.$bizUtil.cloneDeep(this.showSchdlDtl)

        if (!_.isEmpty(this.lv_ImgList)) {
          schdlItem.imageList = this.lv_ImgList
          this.lv_ImgListBackup = this.$bizUtil.cloneDeep(this.lv_ImgList)
        }

        let pFcSchdlSno = 0
        if (this.udtFcSchdlSno > 0) {
          pFcSchdlSno = Number(this.udtFcSchdlSno)
        } else {
          pFcSchdlSno = Number(this.showSchdlDtl.fcSchdlSno)
        }

        // 관리자 일정 수정
        let popup303 = this.$bottomModal.open(MSPAP303P, {
          properties: {
            pSchdlItem: schdlItem,
            pFcSchdlSno: pFcSchdlSno,
            pMobslChnlCustId: this.showSchdlDtl.mobslChnlCustId,
            pCustAlctnCmpgnId: this.showSchdlDtl.custAlctnCmpgnId
          },
          listeners: {
            onPopupClose: () => {
              // 취소 버튼
              this.$bottomModal.close(popup303);

              lv_Vm.lv_ImgList = lv_Vm.lv_ImgListBackup
            },
            fn_OnRefresh: (auth, pData) => {
              // 확인 버튼
              this.$bottomModal.close(popup303);

              if (auth === 'U') {
                // 일정 내용 재조회
                lv_Vm.fn_SearchSchedule(pData)
                lv_Vm.isUdtData = true
              }
            }
          }
        })

      },

      fn_SearchSchedule(pData) {
        const lv_Vm = this
        let trnstId = 'txTSSAP20S1'
        const auth = 'S'

        if (this.showSchdlDtl.type === '3') { // 공유 일정 조회
          trnstId = 'txTSSAP20S2'
        }

        let pParam = {
          userEno: this.showSchdlDtl.regntNo,
          fcSchdlSno: Number(this.showSchdlDtl.fcSchdlSno)
        }

        if (!_.isEmpty(pData)) {
          pParam.fcSchdlSno = pData.fcSchdlSno
          this.udtFcSchdlSno = pData.fcSchdlSno
        }

        this.post(lv_Vm, pParam, trnstId, auth)
          .then(res => {
            console.log(res)
            if (_.isEmpty(res.body)) return

            let schdlInfoSVO = res.body

            lv_Vm.showSchdlDtl.title = schdlInfoSVO.fcSchdlNm // 일정명

			// 등록지점 (전체)일경우, 등록자 지점을 지역단 혹은 사업부로 셋팅
            lv_Vm.showSchdlDtl.regntFofOrgNm = schdlInfoSVO.regntFofOrgNm // 등록지점명
            if (this.$bizUtil.isEmpty(schdlInfoSVO.regntFofOrgNm)) {
              lv_Vm.showSchdlDtl.regntFofOrgNm = schdlInfoSVO.shareDofOrgNoNm
            } else if (this.$bizUtil.isEmpty(schdlInfoSVO.shareDofOrgNoNm)) {
              lv_Vm.showSchdlDtl.regntFofOrgNm = schdlInfoSVO.shareHofOrgNoNm
            }

            lv_Vm.showSchdlDtl.regntNm = schdlInfoSVO.regntNm // 등록자명

            lv_Vm.showSchdlDtl.shareHofOrgNo = schdlInfoSVO.shareHofOrgNo // 공유 대상 사업부명
            lv_Vm.showSchdlDtl.shareHofOrgNoNm = schdlInfoSVO.shareHofOrgNoNm // 공유 대상 사업부명
            lv_Vm.showSchdlDtl.shareDofOrgNo = schdlInfoSVO.shareDofOrgNo // 공유 대상 지역단명
            lv_Vm.showSchdlDtl.shareDofOrgNoNm = schdlInfoSVO.shareDofOrgNoNm // 공유 대상 지역단명
            lv_Vm.showSchdlDtl.shareFofOrgNo = schdlInfoSVO.shareFofOrgNo // 공유 대상 지점명
            lv_Vm.showSchdlDtl.shareFofOrgNoNm = schdlInfoSVO.shareFofOrgNoNm // 공유 대상 지점명

            // 뱃지 셋팅
            apConstants.mngrCustCntc.forEach(v => {
              if (v.id === schdlInfoSVO.custCntcId) {
                lv_Vm.showSchdlDtl.CustCntcNm = v.name
              }
            })
            apConstants.fcCustCntc.forEach(v => {
              if (v.id === schdlInfoSVO.custCntcId) {
                lv_Vm.showSchdlDtl.CustCntcNm = v.name
              }
            })

            // 일시
            lv_Vm.showSchdlDtl.startDate = moment(schdlInfoSVO.fcSchdlStrYmd).format('YYYY-MM-DD')
            if (!lv_Vm.$bizUtil.isEmpty(schdlInfoSVO.fcSchdlStrHm)) {
              lv_Vm.showSchdlDtl.startTime = schdlInfoSVO.fcSchdlStrHm.substring(0,2) + ":" + schdlInfoSVO.fcSchdlStrHm.substring(2,4)
            } else {
              lv_Vm.showSchdlDtl.startTime = null
            }

            lv_Vm.showSchdlDtl.endDate = moment(schdlInfoSVO.fcSchdlEndYmd).format('YYYY-MM-DD')
            if (!lv_Vm.$bizUtil.isEmpty(schdlInfoSVO.fcSchdlEndHm)) {
              lv_Vm.showSchdlDtl.endTime = schdlInfoSVO.fcSchdlEndHm.substring(0,2) + ":" + schdlInfoSVO.fcSchdlEndHm.substring(2,4)
            } else {
              lv_Vm.showSchdlDtl.endTime = null
            }
            
            lv_Vm.showSchdlDtl.place = schdlInfoSVO.fcSchdlPrmsPlcNm   // 장소
            lv_Vm.showSchdlDtl.fcSchdlCntnt= schdlInfoSVO.fcSchdlCntnt // 일정 메모

            // schdlInfoSVO.fcIapReciYn
            lv_Vm.showSchdlDtl.fcIapTmScCd = schdlInfoSVO.fcIapTmScCd // 알림 타입 (1: 당일, 2: 전날)
            lv_Vm.showSchdlDtl.fcIapHm = schdlInfoSVO.fcIapHm         // 알림 시각

            if (schdlInfoSVO.fcIapTmScCd === '1') { // 당일
              lv_Vm.fcIapDay = schdlInfoSVO.fcSchdlStrYmd
            } else if (schdlInfoSVO.fcIapTmScCd === '2') { // 전날
              let tmp = schdlInfoSVO.fcSchdlStrYmd.replaceAll('-', '')
              lv_Vm.fcIapDay = moment(tmp).add(-1, 'd').format('YYYY-MM-DD')
            }

            if (!lv_Vm.$bizUtil.isEmpty(schdlInfoSVO.fcIapHm)) {
              lv_Vm.fcIapHm = schdlInfoSVO.fcIapHm.substring(0,2) + ":" + schdlInfoSVO.fcIapHm.substring(2,4)
            }

            // 첨부 이미지 조회
            // 일정일시 3개월후는, FC는 못보고 / 등록자(모든) 업로드시점 1년동안 볼수있다
            if (!lv_Vm.$bizUtil.isEmpty(schdlInfoSVO.sharePmtImgNm)) {
              let sDate = moment(schdlInfoSVO.fcSchdlStrYmd).format('YYYYMMDD') // 일정 시작일
              let d3Month = moment(new Date().setMonth(new Date().getMonth() - 3)).format('YYYYMMDD')
              let isFc = window.vue.getStore('userInfo').getters.getUserInfo.isFc === 'Y'

              if (!isFc) {
                // 조회할 이미지가 있을 경우
                if (!lv_Vm.$bizUtil.isEmpty(schdlInfoSVO.sharePmtImgNm)) {
                  lv_Vm.fn_LoadImg()
                }
              }else if (lv_Vm.getStore('userInfo').getters.getUserInfo.userId !== schdlInfoSVO.regntNo) {
                if (sDate >= d3Month) {
                  // 조회할 이미지가 있을 경우
                  if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.showSchdlDtl.sharePmtImgNm)) {
                    lv_Vm.fn_LoadImg()
                  }
                }
              }
            }

            console.log('Select POST Call END!!')
          })
          .catch(err => {
            window.vue.error(err)
          })
      },
      /*********************************************************
       * Function명: fn_ResetUptData
       * 설명: 일정 수정 데이터 바인딩
       *********************************************************/
      fn_ResetUptData (pData) {
        if (_.isEmpty(pData)) return

        this.showSchdlDtl.title = pData.fcSchdlNm
        this.showSchdlDtl.CustCntcNm = pData.CustCntcNm

        this.showSchdlDtl.startDate = moment(pData.fcSchdlStrYmd).format('YYYY-MM-DD')
        this.showSchdlDtl.startTime = pData.fcSchdlStrHm
        this.showSchdlDtl.endDate = moment(pData.fcSchdlEndYmd).format('YYYY-MM-DD')
        this.showSchdlDtl.endTime = pData.fcSchdlEndHm

        this.showSchdlDtl.place = pData.fcSchdlPrmsPlcNm
        this.showSchdlDtl.type = pData.fcSchdlScCd
        this.showSchdlDtl.mobslChnlCustId = pData.mobslChnlCustId
        this.showSchdlDtl.custAlctnCmpgnId = pData.custAlctnCmpgnId
        this.showSchdlDtl.fcIapTmScCd = pData.fcIapTmScCd
        this.showSchdlDtl.fcIapHm = pData.fcIapHm
        this.showSchdlDtl.custCntcId = pData.custCntcId
        this.showSchdlDtl.fcSchdlCntnt = pData.fcSchdlCntnt

        this.lv_ImgList = []
        if (!this.$bizUtil.isEmpty(pData.ImgFstData)) {
          this.lv_ImgList.push(pData.ImgFstData)
        }
        if (!this.$bizUtil.isEmpty(pData.imgScndData)) {
          this.lv_ImgList.push(pData.imgScndData)
        }

        this.showSchdlDtl.shareHofOrgNo   = pData.shareHofOrgNo
        pData.shareHofOrgNm = pData.shareHofOrgNm.split(' ')[0]
        this.showSchdlDtl.shareHofOrgNoNm = pData.shareHofOrgNo === '0' ? null : pData.shareHofOrgNm
        this.showSchdlDtl.shareDofOrgNo   = pData.shareDofOrgNo
        pData.shareDofOrgNm = pData.shareDofOrgNm.split(' ')[0]
        this.showSchdlDtl.shareDofOrgNoNm = pData.shareDofOrgNm === '0' ? null : pData.shareDofOrgNm
        this.showSchdlDtl.shareFofOrgNo   = pData.shareFofOrgNo
        pData.shareFofOrgNm = pData.shareFofOrgNm.split(' ')[0]
        this.showSchdlDtl.shareFofOrgNoNm = pData.shareFofOrgNm === '0' ? null : pData.shareFofOrgNm

      },
      /*********************************************************
       * Function명: fn_DeleteProcess
       * 설명: 삭제처리 프로세스
       *********************************************************/
      fn_DeleteProcess () {
          var lv_Vm = this
          var trnstId = 'txTSSAP20D2'
          var pParam = {
            userEno: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
            fcSchdlSno: Number(lv_Vm.showSchdlDtl.fcSchdlSno),
            fcSchdlScCd: lv_Vm.showSchdlDtl.type,
            fcSchdlStrYmd: lv_Vm.showSchdlDtl.startDate.replaceAll('-', ''),
            fcSchdlEndYmd: lv_Vm.showSchdlDtl.endDate.replaceAll('-', ''),
            fcSchdlStrHm: lv_Vm.showSchdlDtl.startTime === null ? ' ' : lv_Vm.showSchdlDtl.startTime.replaceAll(':', ''),
            fcSchdlEndHm: lv_Vm.showSchdlDtl.endTime === null ? ' ' : lv_Vm.showSchdlDtl.endTime.replaceAll(':', ''),
            fcSchdlNm: lv_Vm.showSchdlDtl.title,
          }


          if (this.udtFcSchdlSno > 0) {
            pParam.fcSchdlSno = Number(this.udtFcSchdlSno)
          }

          lv_Vm.post(lv_Vm, pParam, trnstId, 'D')
            .then(function (response) {
              if (response.msgComm.msgCd === 'ECOU004') {
                lv_Vm.getStore('toast').dispatch('ADD', '삭제되었습니다.')
                lv_Vm.fn_delete()
              } else {
                console.log('삭제 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc )
              }
            })
      },
      fn_close() {
        if (this.isUdtData) { // 수정했을 경우, 재조회
          this.$emit('fn_OnRefresh')
        } else {
          this.$emit('onPopupClose')
        }
      },
      fn_delete() {
        this.$emit('fn_OnRefresh')
      },
      /*********************************************************
       * Function명: fn_ZoomViewer
       * 설명: 이미지 줌 플러그인 호출
       *********************************************************/
      fn_ZoomViewer (idx) {
        let imgData = this.lv_ImgList[idx]

        window.fdpbridge.exec('ZoomViewerPlugin', {
          img: imgData
        }, () => {
          console.log(':: ZoomPlugin success')
        }, (error) => {
          console.log(':: ZoomPlugin fail')
          window.vue.error(error)
        });

      },
      /*********************************************************
       * Function명: fn_ImgClick
       * 설명: 이미지 클릭 이벤트
       *********************************************************/
      fn_ImgClick(idx) {
        this.fn_ZoomViewer(idx)
      }

    }
  };
</script>
<style scoped>
</style>